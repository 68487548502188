<template>
    <template v-if="Array.isArray(txt)">
        <div :class="classes" @click="clicked">
            <p v-for="(paragraph, i) in txt" :key="i">
                <Icon v-if="icon" :v="icon" /><LinkedText :text="paragraph" />
            </p>
        </div>
    </template>
    <template v-else>
        <Icon v-if="icon" :v="icon" /><LinkedText :text="txt" :class="classes" @click="clicked" />
    </template>
</template>

<script>
import { mapState } from 'pinia';
import { sessionCookieSetting } from '../src/cookieSettings.ts';
import useDialogue from '../composables/useDialogue.ts';
import { useMainStore } from '../store/index.ts';

export default {
    props: {
        params: {},
        icon: {},
    },
    setup() {
        const translationsCookie = useCookie('translations', sessionCookieSetting);
        return {
            store: useMainStore(),
            dialogue: useDialogue(),
            translationsCookie,
        };
    },
    data() {
        return {
            key: (this.$slots.default()[0].children || '').trim(),
        };
    },
    computed: {
        ...mapState(useMainStore, [
            'translationMode',
            'translationChanges',
        ]),
        modified() {
            return this.translationMode && this.translationChanges.hasOwnProperty(this.key);
        },
        txt() {
            return this.modified
                ? this.$translator.applyParams(this.translationChanges[this.key], this.params || {})
                : this.$translator.translate(this.key, this.params || {});
        },
        classes() {
            return [this.translationMode ? 't-translation-mode' : '', this.modified ? 't-modified' : ''];
        },
    },
    methods: {
        async clicked(e) {
            if (!this.translationMode) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            const base = this.$translator.get(this.key, false, true);

            const newValue = await this.dialogue.editor(
                this.modified
                    ? this.translationChanges[this.key]
                    : this.$translator.get(this.key),
                {
                    icon: 'language',
                    header: this.key,
                    message: base
                        ? `<div class="small alert alert-info">${
                            Array.isArray(base)
                                ? `<ul>${base.map((el) => `<li>${el}</el>`)}</ul>`
                                : base
                        }</div>`
                        : undefined,
                    margin: false,
                },
                'info',
            );

            if (newValue !== undefined) {
                this.store.translate({ translator: this.$translator, key: this.key, newValue });
                this.translationsCookie = this.store.translationChanges;
            }
        },
    },
};
</script>

<style lang="scss">
@import "assets/variables";

.t-translation-mode {
    border: 1px solid rgba($primary, 50%);
    cursor: alias;
    &:hover {
        border: 1px solid $primary;
    }
}

.t-modified {
    background-color: rgba($primary, 30%);
}
</style>
